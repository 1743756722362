import { FormattedMessage } from 'react-intl';
import SVG0 from '../../../styles/svgs/svg0.svg';
import SVG4 from '../../../styles/svgs/svg4.svg';
import Typography from '../Typography';
import { MiddleNumberContainer, NotFoundWrapper, NumberContainer } from './styles';

export type NotFoundPageProps = {
  image?: string;
};

const NotFoundPage: React.FC<NotFoundPageProps> = ({ image }) => {
  return (
    <div style={{ width: '100%' }}>
      <NotFoundWrapper>
        <img src={image || '/new-breadcrumb.png'} alt="Blue gradient" />
        <div>
          <div>
            <NumberContainer>
              <SVG4 />
            </NumberContainer>
            <MiddleNumberContainer>
              <SVG0 />
            </MiddleNumberContainer>
            <NumberContainer>
              <SVG4 />
            </NumberContainer>
          </div>
          <Typography variant="h2" color="white" fontWeight="600">
            <FormattedMessage id="404.message" />
          </Typography>
        </div>
      </NotFoundWrapper>
    </div>
  );
};

export default NotFoundPage;
