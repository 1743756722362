import styled from 'styled-components';
import media from '../../../styles/media';

export const NotFoundWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.blue};

  > img {
    width: 100%;
    height: 100%;
  }

  > div {
    color: #ffffff;
    padding-top: 100px;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    > div {
      display: flex;
      margin-bottom: 20px;
      transform: scale(0.5);

      ${media('small')} {
        transform: scale(1);
      }
    }
    > h2 {
      text-align: center;
      color: #fff;
      margin: 0;
      font-weight: 600;
      font-size: 20px;
      line-height: 1.2em;
      ${media('medium', 'large')} {
        font-size: 25px;
      }
      ${media('large')} {
        font-size: 40px;
      }
    }
  }
`;

export const NumberContainer = styled.div`
  -webkit-animation: mover 3s infinite alternate ease;
  animation: mover 3s infinite alternate ease;
  @-webkit-keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  @-webkit-keyframes moverInverse {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(20px);
    }
  }
  @keyframes moverInverse {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(20px);
    }
  }
`;

export const MiddleNumberContainer = styled(NumberContainer)`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  -webkit-animation: moverInverse 3s infinite alternate ease;
  animation: moverInverse 3s infinite alternate ease;
`;
