import Head from 'next/head';
import { gql } from '@apollo/client';
import { GetServerSideProps } from 'next';
import NotFoundPage from '../components/ui/NotFoundPage';
import { initializeApollo } from '../utils/apollo';
import { HEADER_FOOTER_QUERY } from '../queries/menu';
import PageLayout, { MenuProps } from '../layouts/Page';
import { flatten } from '../utils/object';
import logging from '../utils/logging';
import { useRouter } from 'next/router';
import React from 'react';

type Props = { image: string; menus: MenuProps; locale: string; previousUrl: string };

const ErrorPage: React.FC<Props> = ({ menus, locale, previousUrl }) => {
  const router = useRouter();

  React.useEffect(() => {
    const referer = previousUrl || globalThis?.sessionStorage?.getItem('currentPath');

    logging.error('404 page', { url: router.asPath, referer });
  }, []);

  return (
    <PageLayout menus={menus} locale={locale} isHeaderTransparent>
      <Head>
        <title>App Masters - 404</title>
        <meta name="robots" content="noindex" />
      </Head>
      <NotFoundPage />
    </PageLayout>
  );
};

export const getServerSide: GetServerSideProps = async ({ locale, req }) => {
  const apolloClient = initializeApollo();
  const { data } = await apolloClient.query({
    query: gql`
      query NotFoundView {
        ${HEADER_FOOTER_QUERY}
      }
    `,
  });
  const newData = flatten(data);

  return {
    props: {
      locale,
      previousUrl: req.headers.referer,
      menus: {
        header: newData.header,
        technologies: newData.technologies,
        services: newData.services,
        footerPt: newData.footerPt,
        footerEn: newData.footerEn,
      },
    },
  };
};

export default ErrorPage;
